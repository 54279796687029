<template>
  <div data-aos="fade-up" data-aos-duration="600">
    <div class="wpo-breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wpo-breadcumb-wrap">
              <h2>Blog</h2>
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><span>Blog</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="wpo-blog-pg-section section-padding">
      <div class="container">
        <div class="row">
          <div class="col col-md-8">
            <div class="gooey" v-if="blog_data.length === 0" style="height: 500px">
              <img src="../assets/images/Infinity-loader.gif" alt="">
            </div>
            <div class="wpo-wpo-blog-content" v-else>
              <div class="post format-video" data-aos="fade-up" data-aos-duration="600" v-for="blog in blog_data" :key="blog.id">
                <template v-if="blog.status === 'published'">
                  <div class="entry-media video-holder" v-if="blog.video_url">
                    <img :src="blog.image" @error="setAltImg" alt>
                    <a href="#" @click.prevent="openVideo(blog.video_url)" class="video-btn">
                      <i class="fi flaticon-play-button-2"></i>
                    </a>
                  </div>
                  <div class="entry-media" v-else>
                    <img :src="blog.image" @error="setAltImg" alt>
                  </div>
                  <ul class="entry-meta">
                    <li v-if="blog.author"><b>By</b> <a>{{blog.author}}</a></li>
                    <li v-if="blog.date"><a><i class="ti-calendar"></i> {{blog.date | moment("MMMM DD, YYYY")}}</a></li>
                  </ul>
                  <h3><router-link :to="{ name: 'article', params: params({url: blog.slug}) }">{{blog.title}}</router-link></h3>
                  <p>{{ trimMyString(blog.introduction, 300) }}</p>
                  <router-link :to="{ name: 'article', params: params({url: blog.slug}) }" class="read-more">Read More...</router-link>
                </template>
              </div>
              <div class="pagination-wrapper pagination-wrapper-left">
                <ul class="pg-pagination">
                  <router-link v-for="num in pages" :key="num.num" :to="{ query: query({offset: num.offset})}" tag="li" active-class="active" ><a>{{num.num}}</a></router-link>
                </ul>
              </div>
            </div>
          </div>
          <div class="col col-md-4">
            <blogLeftContent />
          </div>
        </div>
      </div> <!-- end container -->
    </section>

    <modal name="video" width="80%" height="90%">
      <div class="video-modal">
        <LazyYoutube :src="modalVideo" maxWidth="100%" />
      </div>
    </modal>
  </div>
</template>

<script>
    import api from "../services/api";
    import blogLeftContent from '../components/blog-left-content';
    const vueLazytube = require('vue-lazytube');
    const { LazyYoutube } = vueLazytube;
    
    export default {
        name: "blog",
        data() {
            return {
                blog_data: [],
                pages: [
                    {
                        num: 1,
                        offset: 0
                    },
                    {
                        num: 2,
                        offset: 3
                    },
                    {
                        num: 3,
                        offset: 6
                    }
                ],
                page: 1,
                modalVideo: '',
                recentBlog_data: [],
                popularPosts_data: [],
                offset: 0,
                limit: 3
            }
        },
        methods: {
            init() {
                window.scrollTo(0,0);
                if (this.$route.query.offset >= 0) {
                    this.getBlog(this.$route.query.offset, this.$route.query.limit);
                } else {
                    this.$router.push({path: '/'});
                }
            },
            openVideo(video) {
                this.$modal.show('video');
                this.modalVideo = video;
            },
            async getBlog(offset, limit) {
                this.blog_data = await api.blog(offset, limit);
                this.blog_data = this.blog_data.data;
                for (const value of this.blog_data) {
                    if (value.image !== null && typeof value.image === "number") {
                        let img = await api.getFiles(value.image);
                        if (img !== null) {
                            value.image = img.data['data']['full_url'];
                        }
                    }
                }
            },
            setAltImg(event) {
                event.target.src = require("@/assets/images/default-image.jpg")
            },
            query(newQuery) {
                return {
                    ...this.$route.query,
                    ...newQuery
                }
            },
            trimMyString (string, maxLength, start = 0) {
                if (string.length > maxLength) {
                    let trimmedString = string.substr(start, maxLength)
                    return (
                        trimmedString.substr(
                            start,
                            Math.min(trimmedString.length,   trimmedString.lastIndexOf(' '))
                        ) + ' ...'
                    )
                }
                return string
            },
            params(newParams) {
                return {
                    ...this.$route.params,
                    ...newParams
                }
            },
        },
        components: {
            blogLeftContent,
            LazyYoutube
        },
        watch: {
            '$route.query': {
                immediate: true,
                handler() {
                    this.init();
                }
            }
        }
    }
</script>

<style scoped>
  .video-modal {
    padding: 10px;
    width: 100%;
    height: 100%;
  }
  iframe {
    border: none;
  }
  .gooey {
    width: 100%;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wpo-blog-pg-section .post p {
    word-break: break-word;
  }
</style>
